import React from "react";
import "./Timer.css";

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const Timer: React.FC = () => {
  const [time, setTime] = React.useState<Time>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const deadline = new Date(2023, 8, 20, 0, 0, 0, 0);

  // count down time and update state
  React.useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const t = deadline.getTime() - now;
      setTime({
        days: Math.floor(t / (1000 * 60 * 60 * 24)),
        hours: Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((t % (1000 * 60)) / 1000),
      });
      if (t <= 0) clearInterval(timer);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      <div className="col-4">
        <div className="box">
          <p className="day">{time.days}</p>
          <span className="text">Days</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p className="hour">{time.hours}</p>
          <span className="text">Hours</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p className="minute">{time.minutes}</p>
          <span className="text">Minutes</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p className="second">{time.seconds}</p>
          <span className="text">Seconds</span>
        </div>
      </div>
    </div>
  );
};
