import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Timer } from './components/Timer/Timer';
import { Preloader } from './components/Preloader/Preloader';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Website
          <br />
          Coming Soon
        </h1>
        <Timer/>
        <Preloader/>
      </div>      
    </div>
  );
}

export default App;
