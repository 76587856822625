import React, { useEffect } from "react";
import "./Preloader.css";

export const Preloader: React.FC = () => {
    useEffect(() => {
        const preloader = document.querySelector(".preloader") as HTMLElement;
        setTimeout(() => {
            preloader.style.opacity = "0";
            setTimeout(() => {
            preloader.style.display = "none";
            }, 1000);
        }, 2000);
    }, []);
    return(
        <div className="preloader">
            <div className="spinner_wrap">
                <div className="spinner"></div>
            </div>
        </div>
    )

};

